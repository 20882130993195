// main container
.container {
    // max-width: $page__maxWidth;
    // min-width: $page__minWidth;
    // width: 100%;
    margin: 0 auto;
}
.pageContent {
    padding: 0px;
    background-color: $secondaryColor;
    @media #{$breaketpoint-mobile} {
        background-color: #ccc;
    }
}
.mainLanding-page {
    margin: 0px auto;
    background-color: $body-bg;
}

.graphSection {
    display: flex;
    flex-direction: column;
    .graphContainer {
        padding: 20px;
        @media #{$breaketpoint-mobile} {
            padding: 5px 0 5px 0;
            width: 98%;
            margin: 0 auto;
        }
    }
    .graphContentCorner {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 20px;
        font-family: HelveticaCondensed;
        /* border-radius: 5px; */
        min-height: 200px;
        background-image: linear-gradient(#133667, #27497e);
      
    }
    .graphContent {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: $white;
        border-radius: 5px;
        padding: 10px;
        min-height: 200px;
        // background: rgba(31, 65, 117, 0.6);
        background-image: linear-gradient(#133667, #27497e);
        @media #{$breaketpoint-mobile} {
            min-height: 100px;
            flex-direction: column;
            padding: 5px;
            align-items: stretch;
        }
        @media screen and (max-width: 1024px) {
            .graphContent{
                flex-direction: row !important;
            }
        }
    }
}

.sectionTitle small {
    small {
        font-size: 16px;
    }
}

.tableHeader-home,
.tableHeader-away {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-radius: 5px 5px 0 0;
    @media #{$breaketpoint-mobile} {
        padding: 5px 6px;
    }
    .tableHeading {
        color: $white;
        font-family: HelveticaCondensed;
        font-weight: 600;
        font-size: 20px;
        @media #{$breaketpoint-mobile} {
            font-size: 15px;
        }
        > span {
            font-weight: normal;
            font-size: 14px;
            @media #{$breaketpoint-mobile} {
                font-size: 12px;
            }
        }
    }

    .tableHeading-rightSection {
        justify-content: flex-end;
    }
}

.tableHeader-home {
    background-color: #09b3c6;
}

.tableHeader-away {
    background-color: #f38182;
}

// Tabs
ul.tabsList {
    padding: 0px 20px;
    font-size: -0px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    @media #{$breaketpoint-mobile} {
        padding: 5px 0 0 0px;
        margin-left: 5px;
        text-align: center;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 2px;
        // border-bottom: solid 1px #fff;
    }
    li {
        display: inline;
        margin: 0;
        margin-right: 5px;
        a {
            text-decoration: none;
            position: relative;
            top: -1px;
            padding: 8px 18px;
            font-size: 16px;
            border-bottom-color: #b7b7b7;
            color: #001a69;
            border-radius: 3px 3px 0 0;
            outline: none;
            background-color: $white;
            @media #{$breaketpoint-mobile} {
                padding: 6px 10px;
                font-size: 14px;
                top: 0px;
                background-color: #fff;
                border-radius: 5px 5px 0 0;
            }
        }

        &.active a {
            font-weight: 600;
            background-color: $tertiaryColor;
            color: $white !important;
            border: 0px solid $tertiaryColor;
            border-bottom-color: rgb(63, 109, 245);
            color: $black;
            font-family: inherit;
        }
    }
}

.tabSection {
    // background-color: $white;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding-top: 15px;
    position: relative;
    @media #{$breaketpoint-mobile} {
        padding-top: 5px;
    }
    .tabsContent {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #d7dce9;
        padding-top: 15px;
        // background-color: $white;
        color: $white;
        @media #{$breaketpoint-mobile} {
            padding-top: 1px;
            border-top: 0px solid #d7dce9;
        }
        .tabContent {
            // display: none;
            // padding: 10px 10px;
            // border-top: none;
            width: 100%;
            animation: fadein 0.6s;
        }

        @keyframes fadein {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    .tabTable-content {
        padding: 0px 20px 20px;
        @media #{$breaketpoint-mobile} {
            padding: 0px 5px 5px;
        }
    }
}

// Search field

// #myInput {
//     background-image: url("../images/searchicon.png");
//     background-size: 16px 16px;
//     background-position: 165px 6px;
//     background-repeat: no-repeat;
//     width: 174px;
//     padding: 7px 7px 7px 7px;
//     border: 1px solid #ddd;
//     margin-bottom: 0px;
//     border-radius: 3px;
//     font-size: 14px;
//     font-family: inherit;
// }

// inner tabs
.tab {
    cursor: pointer;
    width: 100%;
    background: $white;
    display: inline-block;
    color: $black;
    font-size: 13px;
}

// .panels {
//     background: $white;
//     width: 100%;
//     overflow: hidden;
//     padding: 5px 0;
// }

// .panel {
//     display: none;
//     animation: fadein 0.8s;
// }

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.panel-title {
    font-size: 15px;
    font-weight: 600;
}

.table-fixture {
    display: flex;
    width: 225px;
    font-weight: 600;
    color: #001a69;
    @media #{$breaketpoint-mobile} {
        // width: 112px;
        width: auto;
        font-size: 10px;
        text-align: left;
        text-transform: uppercase;
        margin-left: 5px;
    }
}

.status {
    display: inline-block;
    text-align: center;
    padding: 2px;
    margin: 1px;
    border-radius: 3px;
    color: $white;
    width: 24px;
    line-height: 20px;
    @media #{$breaketpoint-mobile} {
        width: 16px;
        line-height: 10px;
        text-align: center;
        font-size: 10px;
    }

    &.win {
        background-color: $win;
    }

    &.loss {
        background-color: $loss;
    }

    &.draw {
        background-color: $draw;
    }

    &.High {
        background-color: $high;
    }

    &.Low {
        background-color: $low;
    }

    &.BTTSno {
        background-color: $BTTSno;
    }

    &.BTTSyes {
        background-color: $BTTSyes;
    }
}

.win-predictor {
    display: inline-flex;
    width: 200px;
    padding: 2px 0;

    .percentage {
        display: inline-block;
        padding: 11px 0px;
        text-align: center;
        color: $white;
        font-size: 12px;

        &.win {
            background-color: $winpercent;
        }

        &.draw {
            background-color: $drawpercent;
        }

        &.loss {
            background-color: $losspercent;
        }
    }
}

.form-grid {
    display: flex;
    margin: 2px 0;
    width: 100%;
}

.form-col {
    margin: 1px 2px;
    width: 100%;
}

.form-group {
    &.form-inline {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        @media #{$breaketpoint-mobile} {
            padding-left: 5px;
            
        }
        
    }
    &.form-inline-group-staging {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        
    }
    &.form-inline-group-staging-league {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        justify-content: flex-end;
        margin: 5px 0px 0 0;
    }
    &.form-inline-fixture-result {
        
    }
    .form-label {
        display: flex;
        // font-size: 14px;
        align-items: center;
        margin-right: 10px;
        color: #666;
        margin-bottom: 2px;
    }

    .form-control {
        input,
        select {
            height: 30px;
            border: solid 1px #ccc;
            border-radius: 3px;
            font-size: 13px;
            @media #{$breaketpoint-mobile} {
                height: 25px;
                font-size: 12px;
                float: right;
                // margin-right: 8px;
            }
        }

        input {
            text-indent: 8px;
            @media #{$breaketpoint-mobile} {
                text-indent: 5px;
                padding: 0 2px;
            }
        }

        select {
            color: #252525;
            // width: 100%;
            padding: 2px 6px;
            min-width: 58px;
        }
    }
}

.dropdown-position {
    position: absolute;
    right: 20px;
    top: 6px;
    color: $white;
    @media #{$breaketpoint-mobile} {
        right: 5px;
        top: 6px;
    }
    .seasonDropdown{
        width: 115px;
    }
}
.white {
    color: $white !important;
}
.tableTwo-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 20px;
    @media #{$breaketpoint-mobile} {
        flex-direction: column;
        padding: 0 5px;
    }
    &.noPadding {
        padding: 0;
    }
    .tableHome,
    .tableAway {
        display: flex;
        flex-direction: row;
        width: 50%;
        @media #{$breaketpoint-mobile} {
            width: auto;
            padding: 0px 1px 5px;
        }
    }
    .tableHome {
        justify-content: flex-start;
    }
    .tableAway {
        justify-content: flex-end;
        @media #{$breaketpoint-mobile} {
            justify-content: flex-start;
        }
    }
    .tableFull-width {
        width: 99%;
        @media #{$breaketpoint-mobile} {
            width: 100%;
        }
    }
}

.leagueDropdown {
    width: 100%;
}
.textIcon {
    display: flex;
    justify-content: flex-end;
    .hdcValue{
        text-align: right;
    }
    span {
        margin-right: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
.textIcon-center{
    justify-content: center;
}

// Seasonal stats common properties
.seasonalStats {
    .seasonalStats-container {
        padding: 0px;
    }

    .seasonalStats-content {
        padding: 0 20px 20px;
        @media #{$breaketpoint-mobile} {
            padding: 0 5px 5px;
            /*for page scroll */
    width: auto;
    overflow-x: scroll;
        }
    }
}

.seasonalStats-tablesList {
    display: flex;
    background-color: #bed2ec;
    padding: 10px 0;
    justify-content: center;
    border-bottom: solid 2px $white;
    @media #{$breaketpoint-mobile} {
        padding: 3px 0;
        margin: 0px;
    }
    .listItems {
        display: flex;
        flex-direction: row;
        padding: 0px 100px;
        line-height: 25px;
        text-align: center;
        @media #{$breaketpoint-mobile} {
            padding: 0px;
            white-space: nowrap;
            overflow-x: scroll;
        }
        ul {
            // overflow: hidden;
            li {
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                color: $black;
                @media #{$breaketpoint-mobile} {
                    font-size: 12px;
                }
                a {
                    display: inline;
                    text-align: center;
                    padding: 6px;
                    text-decoration: none;
                    color: $black;
                }
                &:hover {
                    a {
                        color: #272727;
                    }
                }

                &.active {
                    a {
                        color: $tertiaryColor;
                    }
                }
            }

            li:not(:last-child):after {
                content: "|";
            }
        }
    }
}

.sectionHeading {
    background-color: #4b6e9f;
    padding: 10px 20px;
    height: 30px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$breaketpoint-mobile} {
        padding: 6px;
        margin: 0 0px;
        height: inherit;
        line-height: 18px;
    }

    .sectionTitle {
        font-family: HelveticaCondensed;
        font-size: 22px;
        font-weight: 600;
        @media #{$breaketpoint-mobile} {
            font-size: 18px;
            text-transform: uppercase;
        }
        span {
            text-transform: none;
            @media #{$breaketpoint-mobile} {
                text-transform: none;
            }
        }
    }
    .sectionFilter {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }

    .form-label {
        color: $white;
    }
}

.extraLarge {
    font-size: 40px;
}

.large {
    font-size: 25px;
    font-weight: 600;
}

.medium {
    font-size: 20px;
    @media #{$breaketpoint-mobile} {
        font-size: 13px;
    }
}

.small {
    font-size: 15px;
    @media #{$breaketpoint-mobile} {
        text-align: center;
        color: #4b6e9f;
    }
}

.extraSmall {
    font-size: 12px;
}

.formStatus {
    width: 200px;
    @media #{$breaketpoint-mobile} {
        width: auto;
    }
}

.formGroup-container {
    display: flex;
}

.homeBg {
    background-color: #09b3c6;
}

.awayBg {
    background-color: #f17f80;
}
.homeGoals-circle,
.awayGoals-circle {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  .home-text {
    color:$homeTeamColor;
    font-size: 22px;
    max-width: 170px;
    text-align: center;
  }
  .away-text {
    color:$awayTeamColor;
    font-size: 22px;
    max-width: 170px;
    text-align: center;
  }
}
// .home-text {
//   color:$homeTeamColor;
// }
// .away-text {
//   color:$awayTeamColor;
// }
.homeGoals-circle, .awayGoals-circle {
  width: 100px;
  height: 100px;
  font-size: 40px;
  @media #{$breaketpoint-mobile} {
    width: 75px;
    height: 75px;
    font-size: 30px;

}
  &.circle {
    width: 125px;
    height: 125px;
    font-size: 45px;
    @media #{$breaketpoint-mobile} {
        width: 100px;
        height: 100px;
        font-size: 30px;

    }
  }
}
.homeGoals-circle {
  background-color: $homeTeamColor;
}
.awayGoals-circle {
  background-color: $awayTeamColor;
}
.goalsPer-game-margin {
    margin: 10px;
}
.comment{
    background-color: #27497e;
    padding: 10px 5px;
}
.goalsperGame-graph {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;

    .goalsperGame-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 85%;
        align-items: baseline;
    }
    .homeGoals-data,
    .awayGoals-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        min-width: 110px;
    }

    .home-text {
        color: #09b3c6;
        font-size: 13px;
        // text-transform: uppercase;
    }
}
.graphTitle {
    width: 140px;
    text-align: center;
    margin: 0 20px;
    font-family: HelveticaCondensed;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    @media #{$breaketpoint-mobile} {
        margin: 0 10px;
        width: 110px;
    }

    span {
        font-size: 18px;
        text-transform: none;
    }
}

.away-text {
    color: #f38182;
    font-size: 13px;
    // text-transform: uppercase;
}
.goalsperGame-teamNames {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
}

.teamSelected {
    color: $white;
    font-weight: 600;
}
// head-to-head-hkjc-mode

.headTo-headHKJC {
    padding-top: 20px;
    // background-color: $white;
    // border-top: solid 1px #133667;
    @media #{$breaketpoint-mobile} {
        padding-top: 5px;
    }
}
.recentForm-info {
    text-align: center;
    margin: 5px;

    .selectedData {
        border: solid 1px #ddd;
        width: 60%;
        padding: 5px;
        border-radius: 5px;
        line-height: 30px;
        margin: 5px auto;
        .fixture {
            font-weight: 600;
            .score {
                background-color: #001a69;
                color: $white;
                text-align: center;
                padding: 3px 10px;
                border-radius: 4px;
                letter-spacing: 2px;
            }
        }
    }
}
.hiloGraphtables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .heading {
        margin: 20px 0;
        @media #{$breaketpoint-mobile} {
            margin: 10px;
        }
    }
}
.hiLoGraph {
    // display: flex;
    text-align: center;
    // margin: 10px 60px;
    @media #{$breaketpoint-mobile} {
        // margin-bottom: 20px;
        width: 100%;
        max-width: 400px;
    }
}
.hiLoGraph-header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    @media #{$breaketpoint-mobile} {
        margin-bottom: 2px;
        // min-height: 32px;
        min-height: 47px;
        align-items: flex-start;
    }
    .line-title {
        width: 100px;
        justify-content: center;
        @media #{$breaketpoint-mobile} {
            text-align: left;
            padding-left: 10px;
        }
    }
    .mainTitle {
        font-size: 20px;
        font-weight: 600;
        @media #{$breaketpoint-mobile} {
            font-size: 12px;
            line-height: 15px;
        }
    }

    .legend-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        border-radius: 15px;
        border: solid 1px #5901c4;
        padding: 0 5px;
        @media #{$breaketpoint-mobile} {
            font-size: 10px;
            border-radius: 5px;
            padding: 0 5px;
            height: auto;
            border: solid 0px #5901c4;
        }

        .homeLegend,
        .awayLegend {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            margin: 0 5px;
            border-radius: 10px;
            @media #{$breaketpoint-mobile} {
                width: 16px;
                height: 16px;
            }
        }
        .homeLegend {
            background-color: $high;
        }
        .awayLegend {
            background-color: $low;
        }
    }
}
.legend {
    @media #{$breaketpoint-mobile} {
        justify-content: flex-end;
        margin-right: 5px;
    }
}
.barGraph {
    display: flex;
    @media #{$breaketpoint-mobile} {
        justify-content: center;
        margin-bottom: 10px;
    }
}
.hiLoGraph-body {
    display: flex;
}
.lineContent {
    border: solid 1px #405f85;
    border-radius: 4px;
    padding: 0 20px;
    height: 100%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: 43px;
    margin: 0 20px;
    @media #{$breaketpoint-mobile} {
        padding: 0px 8px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        line-height: 28px;
        margin: 0 5px;
    }
}
.barsContent {
    text-align: center;
    @media #{$breaketpoint-mobile} {
        width: 100%;
    }
}
.hiLoBar {
    width: 460px;
    height: 24px;
    display: flex;
    align-items: stretch;
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0;
    align-items: center;
    line-height: 25px;
    @media #{$breaketpoint-mobile} {
        width: auto;
        height: 18px;
        margin: 10px 0;
        line-height: 18px;
        font-size: 11px;
        overflow: hidden;
    }
    .homeBar {
        background-color: #ebbf32;
        flex: var(--blue);
        color: $black;
    }
    .awayBar {
        background-color: #74aa8c;
        flex: var(--red);
    }
}
.bar > * {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sectionMain-title {
    display: flex;
    justify-content: space-between;
    width: 49.5%;
    align-items: center;
    @media #{$breaketpoint-mobile} {
        width: 100%;
    }
}
.internalGraph {
    // check classes
    display: flex;
    justify-content: space-evenly;
    color: $white;
    @media #{$breaketpoint-mobile} {
        background-image: linear-gradient(#133667, #27497e);
        .headtohead-graph {
            flex-direction: column;
            padding-top: 20px;
            align-items: center;
        }
    }
    @media #{$breaketpoint-mobile} {
        .goalScore {
            flex-direction: column;
            align-items: center;
        }
    }
}
.graphGrid {
    margin-top: 0px;
}
.pastSeasons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: $black;
    font-size: 20px;
    font-weight: 600;
    margin: 30px 20px;
    line-height: 30px;
    @media #{$breaketpoint-mobile} {
        flex-direction: column;
        font-size: 15px;
        margin: 5px;
        line-height: 20px;
    }
    .pastSeasons-block {
        border: solid 1px #ddd;
        border-radius: 4px;
        width: 25%;
        padding: 20px;
        text-align: center;
        @media #{$breaketpoint-mobile} {
            margin: 3px auto;
            width: 90%;
            padding: 5px;
            justify-content: center;
        }
    }
    .homeWins {
        background-color: #a9edf5;
    }
    .draws {
        background-color: #e7e7e7;
    }
    .awayWins {
        background-color: #f3c7c7;
    }
}
.pastSeasons-grid {
    background-color: $white;
    margin: 0;
    padding: 10px 0;
    border-bottom: solid 1px #eee;
    @media #{$breaketpoint-mobile} {
        padding: 5px 0;
        border: solid 1px #ddd;
        border-bottom: solid 0px #eee;
    }
    .small {
        padding: 5px 10px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
    }
}
.dualHilo-graph {
    display: flex;
    margin: 0 143px;
    @media #{$breaketpoint-mobile} {
        margin: 5px 0px;
        width: 100%;
    }
    .middleLine {
        text-align: center;
        margin-bottom: 23px;
        @media #{$breaketpoint-mobile} {
            margin-bottom: 23px;
            // min-height: 16px;
            min-height: 30px;
        }
    }
}
sup {
    top: 0;
    @media #{$breaketpoint-mobile} {
        top: -4px;
    }
}
.teamSelection {
    display: flex;
    justify-content: center;

    .teamSelection-heading {
        display: flex;
        width: 350px;
        background-color: $white;
        border-radius: 4px 4px 0 0;
        margin-top: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 15px;
        border-bottom: 1px solid #536697;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #17396b;
        @media #{$breaketpoint-mobile} {
            margin-top: 0px;
            height: 28px;
            font-size: 14px;
        }
    }
}
// table header separation
.th-separator {
    border-right: solid 1px #314977;
}
.th-spaceSeparator {
    border-right: solid 8px #292e3b;
}
hr {
    border: solid 1px #49679e;
}
.fullPage {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.lft_sidebar {
    display: flex;
    flex-direction: column;
    align-items: justify;
    width: 85%;
    line-height: 30px;
    background-color: #f5f7ff;
}
.rft_sidebar {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 15%;
}
.poweredBy {
    display: flex;
    background-color: #efefef;
    align-items: center;
    @media #{$breaketpoint-mobile} {
        flex-direction: column;
    }

    // margin: 15px 0;
    .poweredBy-logo {
        img {
            max-width: 200px;
        }
    }
    .poweredBy-content {
        font-size: 9px;
        line-height: 1.5em;
        padding: 15px;
    }
}
.team-lastForm {
    display: flex;
    @media #{$breaketpoint-mobile} {
        justify-content: center;
        // width: 100px;
    }
}
.font-10 {
    font-size: 10px;
}
// loading css
.noData {
    display: flex;
    line-height: 40px;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
}
.noDatagraph {
    display: flex;
    line-height: 30px;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;
    padding-left: 10px;
    margin: 3px 0 0;
    background-color: #27497e;
}
.loader {
    margin: 0 auto;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid blue;
    border-bottom: 10px solid blue;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    @media #{$breaketpoint-mobile} {
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid blue;
        border-bottom: 5px solid blue;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.formWidth {
    margin: 0 auto;
    width: 85px;
}
.width-140 {
    width: 140px;
}
.besBet-width {
    .highcharts-container {
        width: 100%;
        svg {
            width: 100% !important;
        }
    }
}
.fullWidth {
    width: 100%;
    height: auto;
}

.month-list {
    width: 100%;
    height: 100%;
    > div {
        width: 100%;
        height: 100%;
        line-height: 25px;
    }
}
.monthCarousel ul li.active {
    background-color: #ddd;
    color: #001a69;
    font-weight: 600;
}
.slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    border: solid 1px #fff;
    height: 50px;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
}
.slick-slide {
    > div {
        width: 100%;
        height: 100%;
    }
}
.slick-list {
    :focus {
        outline: none;
    }
    .active {
        background-color: #ddd;
        color: #001a69;
        font-weight: 600;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50px;
        outline: none;
    }
}
/* Head to Head Search */
.width100{
    width: 100% !important;
}
.paddingTop{
    padding-top: 5px;
}
.playerHT-FT {
    width: 66px;
}


// Tournament Bracket
.tournament-container{
    @media #{$breaketpoint-mobile} {
        
        width:auto;
        overflow-x: scroll;
    
        }
}
.tournament-headers {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-bottom: 1px solid #ccc;
    border-top: 1px solid #133667;
    background-color:$primaryColor;
    color: #fff;
    @media #{$breaketpoint-mobile} {
        
    width: 210%;

    }
	h3 {
		width: 25%;
		text-align: center;
		font-weight: 400;
		border-right: 1px solid #133667;
		margin: 0;
		padding: .5rem;
        font-size: 13px;
	}
}
.tournament-brackets {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	background: #fdfdfd;
	margin-bottom: 50px;
    min-height: 628px;
    @media #{$breaketpoint-mobile} {
        
        width: 210%;
    
        }
}
.bracket {
	padding-left: 0;
	display: flex;
	margin: 0;
	padding: 30px 0;
	flex-grow: 1;
	flex-direction: column;
    justify-content: space-around;
	justify-content: space-evenly;
	list-style-type: none;
    max-width: 35%;
    margin: 0 auto;
	// border-right: 1px dashed #ccc;
	flex: 1;
	&:first-of-type {
		.team-item {
			&:before {
				display: none;
			}
		}
	}
	&:last-of-type {
		.team-item {
			&:after {
				display: none;
			}
		}
	}
}
.team-item {
	background-color: #f4f4f4;
    color: #000;
	padding: 0.5rem 0.3rem;
	display: block;
	margin: 0.5rem 10px;
	position: relative;
	// vertical-align: middle;
	line-height: 2;
	text-align: center;
    border: solid 1px #133667;
    font-size: 11px;
    display: flex;
    .countryLogo-small{
        width: 20px !important;
        float: left;
        margin-top: 7px;
        right: 10px;
        padding-left: 0;
    }
	&:after {
		content: "";
		border-color: #082a8e;
		border-width: 1px;
		position: absolute;
		display: block;
		width: 10px;
		right: -11px;
	}
	&:nth-of-type(odd) {
		&:after {
			border-right-style: solid;
			border-top-style: solid;
			height: 140%;
			top: 50%;
		}
	}
	&:nth-of-type(even) {
		&:after {
			border-right-style: solid;
			border-bottom-style: solid;
			height: 140%;
			top: -88%;
		}
	}
	&:before {
		content: "";
		border-top: 1px solid #082a8e;
		position: absolute;
		height: 2px;
		width: 10px;
		left: -11px;
		top: 50%;
        // top: -3%;
        //     width: 0;
        //     height: 0;
        //     border-top: 23px solid transparent;
        //     border-left: 10px solid #082a8e;
        //     border-bottom: 23px solid transparent;
	}
	// time {
	// 	display: inline-block;
	// 	background-color: #dbdbdb;
	// 	font-size: 0.8rem;
	// 	padding: 0 0.6rem;
	// }
    // .countryName{
    //    
    // }
    .matchTime {
        display: inline-block;
		background-color: #dbdbdb;
		font-size: 0.8rem;
		padding: 0 0.6rem;
    }
}
.countryLogo-small{
        width: 20px !important;
        float: left;
        margin-top: 5px;
    }
    .score{
        color: #000;
        float: right;
        font-size: 18px;
        font-weight: 600;
        padding: 0px;
        height: 24px;
        display: flex;
        width: 25px;
        justify-content: center;
        align-items: center;
    }
.KickOf-time{
    position: relative;
    margin: 0 auto;
    color: red;
    height: 20px;
    text-align: center;
}
.team-item-logo{
    position: relative;
    // top:25px;
    text-align: center;
}
.team-item-result{
    position: absolute;
    top: 20%;
    left: 10px;
    width: calc(100% - 22px);
    text-align: center;
    border: solid 1px #082a8e;
    color: #000;
    height: auto;
    background-color: #eee;
    .team-item-resultContent{
        padding: 0.5rem 0.3rem;
    }
    &.result-info{
        top: unset;
        bottom: 20%;
    }
    .teamCup{
        position: absolute;
        top: -38px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .matchTime {
        display: inline-block;
		background-color: #dbdbdb;
		font-size: 0.8rem;
		padding: 0 0.6rem;
    }
}
.team-item-result1{
    position: absolute;
    text-align: center;
    color: #000;
    margin: 0 auto;
    padding: 5px;
}

.bracket.right {
	.team-item {
        .countryLogo-small{
            width: 20px !important;
            float: right;
            margin-top: 7px;
            right: 10px;
        }
        .score{
            color: #000;
            float: left;
            font-size: 18px;
            font-weight: 600;
            // border-left: unset;
            padding: 1px;
            height: 25px;
            display: flex;
            width: 25px;
            justify-content: center;
            align-items: center;
        }
        .tree-arrow {
            width: 0;
            height: 0;
            border-top: 23px solid transparent;
            border-bottom: 23px solid transparent;
            border-right: 8px solid #082a8e;
            border-left: 0;
            position: absolute;
            left: -8px;
            // top: -1px;
            top: 50%;
            transform: translate(0, -50%);
        }
		&:after {
			content: "";
			border-width: 0;
			position: unset;
			display: unset;
			width: unset;
			right: unset;
			content: "";
			border-top: 1px solid #082a8e;
            top: 50%;
			position: absolute;
			height: 2px;
			width: 10px;
			right: -11px;
            // top: 50%;
			// top: -3%;
            // width: 0;
            // height: 0;
            // border-top: 23px solid transparent;
            // border-right: 10px solid #082a8e;
            // border-bottom: 23px solid transparent;
		}
		&::before {
			content: "";
			border-color: #082a8e;
			border-width: 1px;
			position: absolute;
			display: block;
			width: 10px;
			left: -11px;
		}
		&:nth-of-type(odd) {
			&::before {
				border-left-style: solid;
				border-top-style: solid;
				height: 140%;
				top: 50%;
			}
        }
        
		&:nth-of-type(even) {
			&::before {
				border-left-style: solid;
				border-bottom-style: solid;
				height: 140%;
				top: -90%;
                // for arrow
				border-top: 0;
			}
		}
	}
    
}
.bracket-2 {
background-color: #e6f2ff !important;
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 277%;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 277%;
				top: -224%;
			}
		}
	}
}
.bracket-6 {
background-color: #e6f2ff !important;
	.team-item {
		&:nth-of-type(odd) {
			&::before {
				height: 268% !important;
				top: 50%;
			}
        }
        &:nth-of-type(even) {
			&::before {
				height: 268% !important;
				top: -218% !important;
			}
		}
	}
}
.bracket-3 {
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 563%;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 563%;
				top: -512%;
			}
		}
	}
}
.bracket-5 {
	.team-item {
		&:nth-of-type(odd) {
			&::before {
				height: 563% !important;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:before {
				height: 563% !important;
				top: -513% !important;
			}
		}
	}
}
.bracket-4 {
    background-color: #e6f2ff !important;
    position: relative;
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 700%;
				// top: 50%;
                // for arrow
                top:50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 700%;
				// top: 50%;
                // top: -650%;
                // for arrow
                top:50%;
			}
		}
        &:nth-of-type(even) {
			&::before {
				top:50%;
			}
		}
		&:after {
			height: 0% !important;
            // for arrow
            // width: 0;
            // height: 0;
            // border-top: 23px solid transparent;
            // border-right: 10px solid #082a8e;
            // border-bottom: 23px solid transparent;
		}
    }
    
}


.bracket:last-of-type {
    
    .team-item:after {
        display: none;
    }
}


.tree-arrow{
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 8px solid #082a8e;
    position: absolute;
    right: -8px;
    // top: -1px;
    top: 50%;
    transform: translate(0, -50%);
}

// .react-tiny-popover-container{
//     width: 50% !important;
//     &:hover {
//         width: 100% !important;
//         z-index: 50;
//       }
// }
// .form-inline-fixture-result{
//     .form-control{
//         select{
//             position: relative;
//             top: -22px;
//             right: 0px;
//         }
//     }
// }
.home-bg {
    background-color: #29d9d7;
  }
  .away-bg {
    background-color: #f17f80;
  }
  .white-bg {
    background-color: #fff;
    span{
      font-size: 12px;
      text-transform: lowercase;
    }
  }