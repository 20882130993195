.tableDefault {
  border-collapse: collapse;
  width: 100%;
  background-color: $white;
  line-height: 18px;
  overflow: hidden;
  th,
  td {
    border-bottom: 1px solid #ddd;
    text-align: center;
    @media #{$breaketpoint-mobile} {
      text-align: center;
    }
  }
  thead {
    th {
      padding: 8px 3px;
      background-color: #ddd;
      color: #17396b;
      vertical-align: top;
      @media #{$breaketpoint-mobile} {
        padding: 7px 3px;
      }
    }
  }
  tbody {
    td {
      color: #272727;
      padding: 1px 7px;
      height: 39px;
      @media #{$breaketpoint-mobile} {
        min-height: 30px;
        padding: 1px 2px;
        
      }
    }
    // tr:hover {
    //   background-color: #ddd;
    // }
  }
  &.tournament-table {
    @media #{$breaketpoint-mobile} {
      border: solid 1px #ddd;
    }
  }
  &.tableGraph {
    background-color: transparent;
    th,
    td {
      border-bottom: none;
      text-align: left;
      color: $white;
    }
    thead {
      th {
        padding: 8px;
        background-color: transparent;
        border-bottom: 1px dotted #ddd;
      }
    }
    tbody {
      td {
        padding: 0px 7px;
        height: 39px;
      }
    }
  }
  &.table-stripped {
    th,
    td {
      border: 0px solid #ddd;
      @media #{$breaketpoint-mobile} {
        border-bottom: solid 1px #ccc;
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: #cae4fb;
      }
      tr:hover {
        background-color: #d7dce9;
      }
    }
  }
  &.tableDefault-bg {
    background-color: #fff;
    @media #{$breaketpoint-mobile} {
      background-color: #fff;
      border: solid 1px #ddd;
    }

    td:nth-child(1),
    th:nth-child(1) {
      text-align: left;
    }
    // td:nth-child(2) {
    //   width: 90px;
    // }
    thead {
      th {
        background-color: $white;
        color: #001a69;
        padding: 12px 5px;
        @media #{$breaketpoint-mobile} {
          background-color: #eee;
          padding: 7px 5px;
        }
      }
    }

    .whiteColumn-bg {
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      // width: 100px;
      background-color: $white;
      text-align: center;
      @media #{$breaketpoint-mobile} {
        background-color: #f7f7f7;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
      }
    }
    .noBorder-td {
      border: none;
      padding: 0;
    }
    .teamsTo-score {
      width: 70px;
    }
    .fh-hilo {
      width: 80px;
    }
    .bothTo-score,
    .against {
      width: 100px;
    }
    .teams {
      width: 150px;
    }
  }
  &.tableSeasonal-stats {
    background-color: #273c6a;
    th,
    td {
      color: $white;
      border-bottom: solid 1px #314977;
    }
    td:nth-child(2) {
      width: 150px;
      @media #{$breaketpoint-mobile} {
        width: auto;
        // text-align: left;
      }
    }
    .for {
      width: 120px;
    }
    .against {
      width: 150px;
    }
    thead {
      th {
        background-color: rgb(41, 46, 59);
        color: $white;
        padding: 10px 0;
        vertical-align: middle;
        @media #{$breaketpoint-mobile} {
          padding: 6px 2px;
        }
      }
    }
    tbody {
      tr:hover {
        background-color: none !important;
      }
    }
  }
  &.tableSpaceDividor {
    thead {
      tr {
        &:last-child th {
          border-bottom-color: #292e3b;
        }
        th[rowspan="2"] {
          border-bottom-color: #292e3b;
        }
      }
    }
  }
  &.keyIndicator {
    background-color: #273c6a;
    margin-top: 10px;
    @media #{$breaketpoint-mobile} {
      margin-top: 5px;
    }
    th,
    td {
      color: $white;
      border-bottom: solid 1px #314977;
      font-size: 16px;
      @media #{$breaketpoint-mobile} {
        font-size: 12px;
      }
    }
    thead{
      tr:first-child th{
        &:first-child {
          color: #29d9d7;
          //width: 250px;
          @media #{$breaketpoint-mobile} {
          min-width: 100px;
          width: auto;
          font-size: 13px;
          font-weight: normal;
          }
        }
        &:last-child {
          color: #f17f80;
          //width: 250px;
          @media #{$breaketpoint-mobile} {
          min-width: 100px;
          width: auto;
          font-size: 13px;
          font-weight: normal;
          }
        }
      }
      th {
        padding: 15px 0;
        //font-size: 18px;
        background-color: #292e3b;
        @media #{$breaketpoint-mobile} {
          font-size: 13px;
        }
      }

    }
    tbody {
      td {
        padding: 8px 0;
      }
    }
  }
  &.tableHkjc-mode {
    line-height: 18px;
    color: $black;
    border-radius: 0 0 5px 5px;
    @media #{$breaketpoint-mobile} {
      border: solid 1px #ccc !important;
    }
    th,
    td {
      border-bottom: none;
    }

    thead {
      th {
        padding: 8px;
        height: 28px;
        background-color: #eee;
        color: $black;
      }
    }
    tr {
      border-bottom: solid 1px #eee;
    }
    tr:last-child {
      border-bottom: none;
    }
  }
  &.tableDefault-hkjc-mode {
    line-height: 18px;
    color: $black;
    @media #{$breaketpoint-mobile} {
      border: solid 1px #ddd;
    }
    th,
    td {
      border-bottom: none;
    }
    thead {
      th {
        padding: 8px;
        // height: 28px;
        background-color: #eee;
        color: $black;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
.ShowMore{
  text-align: center;
  color: #fff;
  li{
    list-style: none;
    padding: 10px;
    width: 100%;
    font-weight: 600;
    cursor:pointer;
  }
}
.vertical-line{ 
  border: solid 2px #052754;
  height: 0px !important;
   }
// Table Horizontal Scrolling //
.scroll-table {
  border: none;
  border-right: solid 1px #eee;
  border-collapse: separate;
  border-spacing: 0;
 
  thead th {
    border: none;
    padding: 5px;
    white-space: nowrap;
    background-color: #ddd !important;
  }
  thead tr {
    background-color: #ddd !important;
  }

  tbody td {
    color: #333;
    padding: 10px;
    white-space: nowrap;
  }
}

.scroll-wrapper {
  position: relative;
  background-color: #ddd;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: #ccc; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: #888; 
}
}

.scroll-scroller {
  margin-left: 100px;
  overflow-x: scroll;
  overflow-y: visible;
  // padding-bottom: 5px;
  min-width: 200px;
  // border-left: solid 1px #eee;
  // thead th{
  //   background-color: #ddd;
  // }
}
.scroll-scroller2 {
  margin-left: 200px;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
  min-width: 155px;
}
.scroll-table {
  .scroll-sticky-col {
    left: 0;
    position: absolute;
    top: auto;
    width: 79px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ddd;
    background-color: #fff;
    white-space: normal;
  }

  .scroll-sticky-col2 {
    position: absolute;
    left: 100px;
    top: auto;
    width: 80px;
    display: flex;
    align-items: center;
    border-right: solid 1px #eee;
    background-color: #fff;
    white-space: normal;
  }
}
